import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import Image from './Image'
import ResourceBlockHeading from './ResourceBlockHeading'

const OpenSourceSoftwareComponent = ({
    openSourceSoftwareHeading,
    openSourceSoftware,
}) => {
    return (
        <section id="open-source-software" className={cx('mt-80')}>
            {openSourceSoftwareHeading && (
                <ResourceBlockHeading
                    heading={openSourceSoftwareHeading}
                    viewAllText="Software"
                    viewAllSlug="/open-source-software"
                />
            )}

            {openSourceSoftware && (
                <ul
                    className={cx(
                        'text-white md:grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 md:gap-32 md-d:space-y-32'
                    )}
                >
                    {openSourceSoftware.map((item) => {
                        const {
                            language,
                            languageLogo,
                            packageName,
                            description,
                            url,
                        } = item.node.frontmatter
                        return (
                            <li
                                key={packageName}
                                className={cx(
                                    'relative w-full min-h-400 md:h-400 bg-resources-code',
                                    'group transform lg:hover:scale-105 lg:focus:scale-105 duration-200 ease-in'
                                )}
                            >
                                <a
                                    href={url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={cx('block p-24 w-full h-full')}
                                >
                                    {languageLogo && (
                                        <div
                                            className={cx(
                                                'w-48 bg-gray-900 rounded-full'
                                            )}
                                        >
                                            <Image
                                                image={languageLogo}
                                                alt={language}
                                            />
                                        </div>
                                    )}
                                    {packageName && (
                                        <h3
                                            className={cx(
                                                'inline-block text-21 leading-snug font-semibold mt-16',
                                                'duration-400 group-hover:shadow-underline break-all'
                                            )}
                                        >
                                            {packageName}
                                        </h3>
                                    )}
                                    {description && (
                                        <p
                                            className={cx(
                                                'text-18 leading-relaxed mt-16 break-words'
                                            )}
                                        >
                                            {description}
                                        </p>
                                    )}
                                </a>
                            </li>
                        )
                    })}
                </ul>
            )}
        </section>
    )
}

OpenSourceSoftwareComponent.propTypes = {
    openSourceSoftwareHeading: PropTypes.string,
    openSourceSoftware: PropTypes.array,
}

export default OpenSourceSoftwareComponent
