import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import OpenSourceSoftwareComponent from '../components/OpenSourceSoftwareComponent'

export const SoftwareIndexPageTemplate = ({
    title,
    introduction,
    openSourceSoftware,
}) => {
    return (
        <div className={cx('max-w-1264 mx-auto mb-120 px-24 lg:px-32')}>
            <section className={cx('mt-60 lg:mt-120')}>
                <h1 className={cx('hd-jb text-purple-700')}>{title}</h1>
                {introduction && (
                    <p className={cx('lg:w-1/2 text-21 leading-snug mt-24')}>
                        {introduction}
                    </p>
                )}
            </section>
            {openSourceSoftware && (
                <OpenSourceSoftwareComponent
                    openSourceSoftware={openSourceSoftware}
                    className="mt-48"
                />
            )}
        </div>
    )
}

SoftwareIndexPageTemplate.propTypes = {
    title: PropTypes.string.isRequired,
    introduction: PropTypes.string,
    openSourceSoftware: PropTypes.array,
}

const SoftwareIndexPage = ({ data }) => {
    const { frontmatter } = data.page
    const openSourceSoftware = data.openSourceSoftware.edges

    return (
        <Layout
            title={frontmatter.title}
            description={frontmatter.introduction}
        >
            <SoftwareIndexPageTemplate
                title={frontmatter.title}
                introduction={frontmatter.introduction}
                openSourceSoftware={openSourceSoftware}
                featuredLogoGrid={frontmatter.featuredLogoGrid}
            />
        </Layout>
    )
}

SoftwareIndexPage.propTypes = {
    data: PropTypes.shape({
        page: PropTypes.object,
        openSourceSoftware: PropTypes.array,
    }),
}

export default SoftwareIndexPage

export const pageQuery = graphql`
    query SoftwareIndexPageTemplate {
        page: markdownRemark(
            frontmatter: { templateKey: { eq: "software-index-page" } }
        ) {
            frontmatter {
                title
                introduction
            }
        }
        openSourceSoftware: allMarkdownRemark(
            sort: { order: DESC, fields: frontmatter___date }
            filter: {
                fileAbsolutePath: {
                    regex: "/(collections/open-source-software)/"
                }
            }
        ) {
            edges {
                node {
                    frontmatter {
                        language
                        languageLogo {
                            extension
                            publicURL
                        }
                        description
                        packageName
                        url
                    }
                }
            }
        }
    }
`
